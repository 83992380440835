import { AssetType } from "../../state";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ASSET_TYPES, unixToLocaleDate } from "../../labels";
import { Box, Button, MenuItem, Select, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { AssetsSizes, GlobalSizes } from "../../size";
import { useWsContext } from "../../ws-context";

function AssetCard({ asset, value }: { asset: string; value: AssetType }) {
  const [type, setType] = useState(value.type);
  const [showSaveButton, setShowSaveButton] = useState(false);

  const { mentionsState, dashboardState, updateDashboard, emitNewData } = useWsContext();

  const { type: assetType, ...restValues } = value;

  const alertsCount = mentionsState.filter((mention) => mention.asset === asset).length;

  const formatValue = (key: string, value: (string | number) | { [key: string]: number }) => {
    if (typeof value === "object") {
      return Object.entries(value).map(([key, value]) => {
        return (
          <Typography key={key} mb={GlobalSizes.smallGap} color="text.secondary">
            <strong>{key}: </strong>
            {unixToLocaleDate(value)}
          </Typography>
        );
      });
    }
    if (key.includes("date")) {
      return unixToLocaleDate(value as number);
    }
    return value;
  };

  const deleteAsset = async (asset: string) => {
    console.log("deleting asset", asset);
    if (!dashboardState.assets) return;
    updateDashboard({
      assets: Object.fromEntries(Object.entries(dashboardState.assets).filter(([key]) => key !== asset)),
    });
    emitNewData({
      action: "removeAsset",
      asset: {
        [asset]: dashboardState.assets[asset],
      },
    });
  };

  const saveAsset = async (asset: string) => {
    console.log(dashboardState);

    updateDashboard({
      assets: { ...(dashboardState.assets || {}), [asset]: { ...value, type } },
    });
    emitNewData({
      action: "setAsset",
      asset: {
        [asset]: { ...value, type },
      },
    });
  };

  useEffect(() => {
    const isTypeChanged = type !== value.type;
    setShowSaveButton(isTypeChanged);
  }, [type, value.type]);

  return (
    <Card variant="outlined" sx={{ maxWidth: AssetsSizes.cardMaxWidth, m: GlobalSizes.smallGap }}>
      <CardContent>
        <Stack direction="row" mb={GlobalSizes.smallGap} alignItems="center" justifyContent="space-between">
          <Typography variant="h6" component="div">
            {asset}
          </Typography>
          <Button aria-label="delete" onClick={() => deleteAsset(asset)}>
            X
          </Button>
        </Stack>
        <Select
          value={type}
          onChange={(e) => setType(e.target.value)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{ marginBottom: GlobalSizes.smallGap }}
        >
          <MenuItem disabled value={assetType || ""}>
            Select Type
          </MenuItem>
          {ASSET_TYPES.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
        {Object.entries(restValues).map(([key, value]) => {
          return (
            <Box key={key} mb={GlobalSizes.smallGap} color="text.secondary">
              <strong>{key}: </strong>
              {formatValue(key, value)}
            </Box>
          );
        })}
        <Typography mb={GlobalSizes.smallGap} color="text.secondary">
          <strong>alerts_count: </strong>
          {alertsCount}
        </Typography>
      </CardContent>
      {showSaveButton && (
        <Button onClick={() => saveAsset(asset)} variant="contained">
          Save
        </Button>
      )}
    </Card>
  );
}

export default AssetCard;
