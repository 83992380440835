import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { GlobalSizes } from "../size";
import { labels } from "../labels";
import { Link } from "react-router-dom";
import { MentionType } from "../state";
import InquiriesPieChart from "./charts/InquiriesPieChart";
import InquiriesLineChart from "./charts/InquiriesLineChart";

const stringToHash = (str: string) => {
  return str.split("").reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
};

const stringToColor = (str: string) => {
  const hash = Math.abs(stringToHash(str));
  const hue = hash % 360;
  return `hsl(${hue}, 75%, 65%)`;
};

function InquiriesCharts({ mentions }: { mentions: MentionType[] }) {
  const lineChartAlerts = useMemo(
    () => mentions.filter((m) => m.creation_date && new Date(m.creation_date) >= new Date(new Date().setMonth(new Date().getMonth() - 3))),
    [mentions]
  );

  return (
    <>
      <Box sx={{ p: GlobalSizes.gap, marginBottom: GlobalSizes.mediumGap }}>
        <Typography variant="h6" gutterBottom>
          <Link to="/ai">{labels.ai.title}</Link>
        </Typography>
      </Box>
      <InquiriesPieChart mentions={mentions} stringToColor={stringToColor} />
      {lineChartAlerts.length > 0 && <InquiriesLineChart mentions={lineChartAlerts} stringToColor={stringToColor} />}
    </>
  );
}

export default InquiriesCharts;
