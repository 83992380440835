import { useMemo } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { MentionType } from "../../state";
import { GlobalSizes } from "../../size";

interface InquiriesPieChartProps {
  mentions: MentionType[];
  stringToColor: (str: string) => string;
}

const accumulateInquiries = (mentions: MentionType[]) => {
  const inquiryMap = new Map<string, number>();
  mentions.forEach((mention) => {
    mention.ai_filter?.results?.forEach((result) => {
      inquiryMap.set(result.key, (inquiryMap.get(result.key) || 0) + 1);
    });
  });
  return inquiryMap;
};

function InquiriesPieChart({ mentions, stringToColor }: InquiriesPieChartProps) {
  const pieData = useMemo(() => {
    const inquiryMap = accumulateInquiries(mentions);
    return Array.from(inquiryMap, ([id, value]) => ({ id, value }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 10)
      .map(({ id, value }) => ({
        id,
        value,
        label: id,
        color: stringToColor(id),
      }));
  }, [mentions, stringToColor]);

  return (
    <PieChart
      series={[{ data: pieData, arcLabel: "value", arcLabelRadius: GlobalSizes.midSize }]}
      slotProps={{
        legend: {
          hidden: true,
        },
      }}
      height={GlobalSizes.chartHeight}
    />
  );
}

export default InquiriesPieChart;
