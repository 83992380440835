import { useState } from "react";
import { Container, TextField } from "@mui/material";
import { GlobalSizes } from "../size";
import { MentionType } from "../state";
import { useWsContext } from "../ws-context";

export default function Note({ mention }: { mention: MentionType }) {
  const [label, setLabel] = useState("Note");

  const { updateAlerts, emitNewData } = useWsContext();

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const newNote = e.target.value;
    if (mention.note === newNote) return;
    const { customer_id, url } = mention;
    emitNewData({ action: "updateAlert", mention: { customer_id, url, note: newNote } });
    setLabel("Note (Saved)");
    updateAlerts({ ...mention, note: newNote });
  };

  return (
    <Container sx={{ height: GlobalSizes.cards.height, width: GlobalSizes.cards.width }}>
      <TextField id="filled-multiline-static" label={label} multiline rows={10} fullWidth defaultValue={mention.note} variant="filled" onBlur={handleBlur} />
    </Container>
  );
}
