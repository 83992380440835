import { createContext, useContext, useState, ReactNode } from "react";

interface ClientContextType {
  currentAlert: string;
  setCurrentAlert: (url: string) => void;
}

const ClientContext = createContext<ClientContextType | undefined>(undefined);

export const useClientContext = (): ClientContextType => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error("useClientContext must be used within a ClientContextProvider");
  }
  return context;
};

export const ClientContextProvider = ({ children }: { children: ReactNode }) => {
  const [currentAlert, setCurrentAlert] = useState<string>("");

  return (
    <ClientContext.Provider
      value={{
        currentAlert,
        setCurrentAlert,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
