import { useMemo } from "react";
import { LineChart } from "@mui/x-charts";
import { MentionType } from "../../state";
import { GlobalSizes } from "../../size";

type InquiriesDatasetType = {
  date: number;
  [key: string]: number;
}[];

interface InquiriesLineChartProps {
  mentions: MentionType[];
  stringToColor: (str: string) => string;
}

const buildLineChartData = (mentions: MentionType[], inquiries: string[]) => {
  const dataset = mentions.reduce<{ [date: number]: { [key: string]: number } }>((acc, mention) => {
    if (!mention.creation_date) return acc;
    const date = new Date(mention.creation_date).setHours(0, 0, 0, 0);
    if (!acc[date]) acc[date] = {};
    mention.ai_filter?.results?.forEach((result) => {
      if (inquiries.includes(result.key)) {
        acc[date][result.key] = (acc[date][result.key] || 0) + 1;
      }
    });
    return acc;
  }, {});

  const filteredData: InquiriesDatasetType = Object.entries(dataset)
    .map(([timestamp, data]) => ({
      date: Number(timestamp),
      ...Object.fromEntries(
        Object.entries(data)
          .sort(([, a], [, b]) => b - a)
          .slice(0, 10)
      ),
    }))
    .sort((a, b) => a.date - b.date);

  return filteredData;
};

function InquiriesLineChart({ mentions, stringToColor }: InquiriesLineChartProps) {
  const { lineChartData, allInquiries } = useMemo(() => {
    const allInquiries = mentions.reduce((acc: string[], mention) => {
      mention.ai_filter?.results?.forEach((result) => {
        if (!acc.includes(result.key)) {
          acc.push(result.key);
        }
      });
      return acc;
    }, []);

    const datasetArr = buildLineChartData(mentions, allInquiries);

    const lineChartData: InquiriesDatasetType = datasetArr.map((data) => {
      allInquiries.forEach((key) => {
        if (!data[key]) {
          data[key] = 0;
        }
      });
      return data;
    });

    return { lineChartData, allInquiries };
  }, [mentions]);

  return (
    <LineChart
      dataset={lineChartData}
      slotProps={{ legend: { hidden: true } }}
      xAxis={[
        {
          id: "Days",
          dataKey: "date",
          scaleType: "time",
          valueFormatter: (date) => new Date(date).toLocaleDateString(),
        },
      ]}
      series={allInquiries.map((key) => ({
        id: key,
        label: key,
        dataKey: key,
        stack: "total",
        area: true,
        showMark: false,
        color: stringToColor(key),
      }))}
      height={GlobalSizes.chartHeight}
    />
  );
}

export default InquiriesLineChart;
