import { Button, Container, Stack, Typography } from "@mui/material";
import Loader from "../Components/Loader";
import Bar from "../Components/Bar";
import { COVERAGE, labels } from "../labels";
import AssetCard from "../Components/assets/AssetCard";
import { AssetsSizes, GlobalSizes, pagesContainerMargins } from "../size";
import { useState } from "react";
import NewAsset from "../Components/assets/NewAsset";
import { entries } from "../state";
import { useWsContext } from "../ws-context";

function Assets() {
  const { dashboardState } = useWsContext();
  const [addAsset, setAddAsset] = useState(false);
  return (
    <div>
      <Bar label={labels.assets.title + " | " + labels.assets.subtitle} />
      <Container sx={{ ...pagesContainerMargins }}>
        <Stack direction="column" gap={GlobalSizes.gap}>
          <Typography variant="body2">Coverage: {COVERAGE.join(", ")}</Typography>
          <Button variant="contained" sx={{ maxWidth: AssetsSizes.buttonMaxWidth }} onClick={() => setAddAsset(true)}>
            Add Asset
          </Button>
          <Stack flexDirection="row" flexWrap="wrap">
            {addAsset && <NewAsset setAddAsset={setAddAsset} />}
            {entries(dashboardState.assets).map(([key, value]) => (
              <AssetCard key={key} asset={key} value={value} />
            ))}
          </Stack>
        </Stack>
        <Loader />
      </Container>
    </div>
  );
}

export default Assets;
