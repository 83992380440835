import { createContext, useContext, useState, ReactNode, useEffect, useRef, useCallback } from "react";
import { ApiRequestData, DashboardState, MentionType } from "./state";
import { get, set } from "idb-keyval";
import { getTeam } from "./team-util";
import { useAuth0 } from "@auth0/auth0-react";
import isEqual from "lodash.isequal";

interface WsContextType {
  dashboardState: DashboardState;
  mentionsState: MentionType[];
  isLoadingMentions: boolean;
  emitNewData: (data: ApiRequestData) => void;
  updateAlerts: (newMention: MentionType) => void;
  deleteAlert: (url: string) => void;
  updateDashboard: (newData: DashboardState) => void;
  socketRef: React.MutableRefObject<WebSocket | null>;
}

const WsContext = createContext<WsContextType | undefined>(undefined);

export const useWsContext = (): WsContextType => {
  const context = useContext(WsContext);
  if (!context) {
    throw new Error("useClientContext must be used within a ClientContextProvider");
  }
  return context;
};

export const WsContextProvider = ({ children }: { children: ReactNode }) => {
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const [dashboardState, setDashboardState] = useState<DashboardState>({});
  const [mentionsState, setMentionsState] = useState<MentionType[]>([]);
  const [isLoadingMentions, setIsLoadingMentions] = useState<boolean>(true);

  const socketRef = useRef<WebSocket | null>(null);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const getToken = useCallback(async () => {
    return await getAccessTokenSilently({
      authorizationParams: { audience: `https://1frxzpgh24.execute-api.us-east-1.amazonaws.com/Prod/` },
    });
  }, [getAccessTokenSilently]);

  const emitNewData = async (data: ApiRequestData) => {
    console.log("Emitting new data to WebSocket", data);
    const token = await getToken();
    const team = getTeam() || user?.email;

    if (socketRef.current?.readyState !== WebSocket.OPEN) {
      console.log("WebSocket not open. Reinitializing");
      await initializeWebSocket();
    }

    try {
      socketRef.current?.send(JSON.stringify({ ...data, customer: { id: user?.email, team }, token: `Bearer ${token}` }));
      if (data.action === "disconnect") {
        socketRef.current?.close();
        socketRef.current = null;
      }
    } catch (error) {
      console.error("Error sending data to WebSocket", error);
    }
  };

  const updateAlerts = (newMention: MentionType) => {
    setMentionsState((prev) => {
      const updatedMentions = [...prev];
      const existingIndex = updatedMentions.findIndex((mention) => mention.url === newMention.url);
      if (existingIndex !== -1) {
        updatedMentions[existingIndex] = newMention;
      } else {
        updatedMentions.push(newMention);
      }
      return updatedMentions;
    });
  };

  const deleteAlert = (url: string) => {
    setMentionsState((prev) => {
      return prev.filter((mention) => mention.url !== url);
    });
  };

  const updateDashboard = (newData: DashboardState) => {
    setDashboardState((prev) => {
      return { ...prev, ...newData };
    });
  };

  const initializeWebSocket = useCallback(async () => {
    if (socketRef.current) {
      console.log("WebSocket already initialized");
      return;
    }
    if (user?.email) {
      const token = await getToken();
      const team = getTeam() || user?.email;
      const socket = new WebSocket(`wss://ww7si9kua5.execute-api.us-east-1.amazonaws.com/production?id=${user.email}&team=${team}&token=Bearer ${token}`);
      socketRef.current = socket;

      socket.addEventListener("open", () => {
        console.log("WebSocket opened");
        socket.send(
          JSON.stringify({
            action: "setConnection",
            customer: { id: user?.email, team },
            token: `Bearer ${token}`,
          })
        );
      });

      socket.addEventListener("message", (event) => {
        console.log({ socket, event });

        const { data } = JSON.parse(event.data);
        console.log("WebSocket message received", data);
        if (!data?.customer) {
          console.error("No customer data received");
          return;
        }

        console.log("customer", data?.customer);

        if (data?.dashboard) {
          if (data.dashboard.customer_id !== data.customer.team || user?.email !== data.customer.id) {
            console.error("Invalid customer data received");
            return;
          }
          setDashboardState(data.dashboard);
        }

        if (data?.alerts) {
          if (data?.status === "completed" && !data.alerts.length) {
            setIsLoadingMentions(false);
            return;
          }
          setMentionsState((prev) => {
            const updatedMentions = [...prev];
            let isStateUpdated = false;
            data.alerts.forEach((newAlert: MentionType) => {
              if (newAlert.customer_id !== data.customer.team || user?.email !== data.customer.id) {
                console.error("Invalid alert data received");
                return;
              }
              const existingIndex = updatedMentions.findIndex((mention) => mention.url === newAlert.url);
              if (existingIndex !== -1) {
                if (!isEqual(updatedMentions[existingIndex], newAlert)) {
                  updatedMentions[existingIndex] = newAlert;
                  isStateUpdated = true;
                }
              } else {
                updatedMentions.push(newAlert);
                isStateUpdated = true;
              }
            });
            return isStateUpdated ? updatedMentions : prev;
          });
        }

        if (data?.updatedAlert) {
          if (data.updatedAlert.customer_id !== data.customer.team || user?.email !== data.customer.id) {
            console.error("Invalid alert data received");
            return;
          }
          console.log("Updating alert", data.updatedAlert);
          updateAlerts(data.updatedAlert);
        }

        if (data?.removedAlert) {
          if (data.removedAlert.customer_id !== data.customer.team || user?.email !== data.customer.id) {
            console.error("Invalid alert data received");
            return;
          }
          deleteAlert(data.removedAlert);
        }

        if (data?.error) {
          console.log("Server error : ", data.error);
          const team = getTeam() || user?.email;
          if (data.customer.id !== user?.email || data.customer.team !== team) {
            console.error("mismatch in user email");
            return;
          }
          if (data.statusCode === 401 || data.statusCode === 403) {
            localStorage.clear();
            if (data.statusCode === 401) {
              console.error("User unauthorized");
              logout({ logoutParams: { returnTo: window.location.origin } });
            } else {
              console.error("User Forbidden");
              window.location.reload();
            }
          } else {
            console.log("Invalidate data");
            socket.send(
              JSON.stringify({
                action: "setConnection",
                customer: { id: user?.email, team },
                token: `Bearer ${token}`,
              })
            );
          }
        }
      });

      socket.addEventListener("close", () => {
        console.log("WebSocket closed");
        socketRef.current = null;

        reconnectTimeoutRef.current = setTimeout(() => {
          initializeWebSocket();
        }, 5000);
      });

      socket.addEventListener("error", (error) => {
        console.error("WebSocket error", error);
        socketRef.current = null;
      });
    }
  }, [user?.email, logout, getToken]);

  useEffect(() => {
    const loadCachedData = async () => {
      const team = getTeam() || user?.email;
      const cachedDashboard = await get<DashboardState>(`${user?.email}_${team}_dashboard`);
      const cachedMentions = await get<MentionType[]>(`${user?.email}_${team}_mentions`);

      console.log({ cachedDashboard, cachedMentions });

      if (cachedDashboard) setDashboardState(cachedDashboard);
      if (cachedMentions) setMentionsState(cachedMentions);
    };

    loadCachedData();
  }, [user?.email]);

  useEffect(() => {
    if (!socketRef.current) {
      initializeWebSocket();
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && !socketRef.current) {
        initializeWebSocket();
      }
    };

    const handleWindowFocus = () => {
      if (!socketRef.current) {
        initializeWebSocket();
      }
    };

    window.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleWindowFocus);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleWindowFocus);

      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }

      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [initializeWebSocket]);

  useEffect(() => {
    const setDashboardInIndexedDB = async () => {
      const team = getTeam() || user?.email;
      const indexDBDashboardKey = `${user?.email}_${team}_dashboard`;
      if (Object.keys(dashboardState).length) {
        if (dashboardState.customer_id !== team) {
          console.log("Mismatch in team and customer_id");
          return;
        }
        try {
          set(indexDBDashboardKey, dashboardState);
        } catch (error) {
          console.error("Error setting dashboard in indexedDB", error);
        }
      }
    };
    setDashboardInIndexedDB();
  }, [dashboardState, user?.email]);

  useEffect(() => {
    const setMentionsInIndexedDB = async () => {
      const team = getTeam() || user?.email;
      const indexDBMentionsKey = `${user?.email}_${team}_mentions`;
      if (mentionsState.length) {
        const validMentions = mentionsState.filter((mention) => mention.customer_id === team);
        try {
          set(indexDBMentionsKey, validMentions);
        } catch (error) {
          console.error("Error setting mentions in indexedDB", error);
        }
      }
    };
    setMentionsInIndexedDB();
  }, [mentionsState, user?.email]);

  return (
    <WsContext.Provider
      value={{
        dashboardState,
        mentionsState,
        isLoadingMentions,
        emitNewData,
        updateAlerts,
        deleteAlert,
        updateDashboard,
        socketRef,
      }}
    >
      {children}
    </WsContext.Provider>
  );
};
