import { Typography, Container, Box } from "@mui/material";
import Bar from "../Components/Bar";
import { labels, severity } from "../labels";
import Loader from "../Components/Loader";
import { Link } from "react-router-dom";
import { useWsContext } from "../ws-context";
import InquiriesCharts from "../Components/InquiriesCharts";
import { GlobalSizes, pagesContainerMargins } from "../size";

function Home() {
  const { mentionsState, dashboardState } = useWsContext();

  const flaggedAlerts = mentionsState.filter((m) => m.flagged);
  const linksNotCollectedManually = flaggedAlerts.filter((mention) => mention.collected_manually);
  const childAlerts = mentionsState.filter((m) => m.parent_mention);
  const investigationsInProgress: string[] = [];

  childAlerts.forEach((alert) => {
    if (!alert.parent_mention) return;
    if (!investigationsInProgress.includes(alert.parent_mention)) {
      investigationsInProgress.push(alert.parent_mention);
    }
  });

  const activeMentions = mentionsState.filter((mention) => !mention.hidden);

  const alertsWithAiFilter = activeMentions.filter((m) => m.ai_filter?.results?.length);

  const severityCount: { [key: string]: number } = {};
  activeMentions?.forEach((mention) => {
    const severity = mention?.severity?.toLowerCase();
    if (!severity) return;

    if (severityCount[severity]) {
      severityCount[severity]++;
    } else {
      severityCount[severity] = 1;
    }
  });

  const sortedSeverities = Object.entries(severityCount).sort((a, b) => {
    const severityA = a[0].toUpperCase();
    const severityB = b[0].toUpperCase();
    return severity[severityB].value - severity[severityA].value;
  });

  function alertsFromLastDay() {
    const last24Hours = new Date().getTime() - 24 * 60 * 60 * 1000;
    const mentionsFromLast24Hours = activeMentions?.filter((mention) => new Date(mention.detection_date || 0).getTime() > last24Hours);
    return mentionsFromLast24Hours;
  }

  return (
    <div>
      <Bar label={labels.home.title} />
      <Container sx={{ ...pagesContainerMargins }}>
        <Typography variant="h4" gutterBottom>
          Welcome to Brinker.ai
        </Typography>
        {alertsWithAiFilter.length > 0 && <InquiriesCharts mentions={alertsWithAiFilter} />}
        {!dashboardState.customer_id ? (
          <Loader />
        ) : (
          <Box>
            <Box sx={{ p: GlobalSizes.gap }}>
              <Typography variant="h6" gutterBottom>
                <Link to="/monitoring">{labels.monitoring.title}</Link>
              </Typography>
              <Typography variant="body1">
                Active {labels.monitoring.items}: {activeMentions?.length},{" "}
                {sortedSeverities.map(([severity, count], i) => (
                  <span key={severity}>
                    {severity}: {count}
                    {i !== sortedSeverities.length - 1 ? ", " : ""}
                  </span>
                ))}
              </Typography>
              <Typography variant="body1">
                {labels.monitoring.items} from the last 24 hours: {alertsFromLastDay()?.length}, Flagged: {flaggedAlerts.length}, Hidden
                {labels.monitoring.items}: {mentionsState.length - activeMentions.length}
              </Typography>
            </Box>
            {flaggedAlerts.length > 0 && (
              <Box p={1}>
                <Typography variant="h6" gutterBottom>
                  <Link to="/flagging"> {labels.flagging.title} </Link>
                </Typography>
                <Typography variant="body1">
                  Total {labels.flagging.items}: {flaggedAlerts.length}
                </Typography>
                <Typography variant="body1">
                  {labels.flagging.items} collected manually: {linksNotCollectedManually?.length}
                </Typography>
              </Box>
            )}
            {flaggedAlerts.length > 0 && (
              <>
                <Box p={1}>
                  <Typography variant="h6" gutterBottom>
                    <Link to="investigation">{labels.investigation.title}</Link>
                  </Typography>
                  <Typography variant="body1">
                    Total {labels.investigation.title}s: {flaggedAlerts.length}
                  </Typography>
                  <Typography variant="body1">
                    {labels.investigation.title}s in progress: {investigationsInProgress.length}
                  </Typography>
                </Box>
                <Box p={1}>
                  <Typography variant="h6" gutterBottom>
                    <Link to="/action">{labels.takeAction.title}</Link>
                  </Typography>
                  <Typography variant="body1">
                    Total {labels.takeAction.items}: {flaggedAlerts.length}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        )}
        <Typography fontSize={8}>
          <a href="/push-data.json" target="_blank" rel="noopener noreferrer">
            Version data
          </a>
        </Typography>
      </Container>
    </div>
  );
}

export default Home;
