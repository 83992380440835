import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Button, Select, MenuItem, TextField, Stack } from "@mui/material";
import { ASSET_TYPES } from "../../labels";
import { AssetsSizes, GlobalSizes } from "../../size";
import { useWsContext } from "../../ws-context";

interface NewAssetProps {
  setAddAsset: React.Dispatch<React.SetStateAction<boolean>>;
}

function NewAsset({ setAddAsset }: NewAssetProps) {
  const [newAsset, setNewAsset] = useState({ name: "", type: "" });
  const { updateDashboard, dashboardState, emitNewData } = useWsContext();

  const saveAsset = async () => {
    setAddAsset(false);
    updateDashboard({
      assets: { ...(dashboardState.assets || {}), [newAsset.name]: { type: newAsset.type } },
    });

    emitNewData({
      action: "setAsset",
      asset: {
        [newAsset.name]: { type: newAsset.type },
      },
    });
  };

  return (
    <Card variant="outlined" sx={{ maxWidth: AssetsSizes.cardMaxWidth, m: GlobalSizes.smallGap }}>
      <CardContent>
        <TextField label="Asset Name" value={newAsset.name} onChange={(e) => setNewAsset({ ...newAsset, name: e.target.value })} fullWidth margin="normal" />
        <Stack direction="column" gap={GlobalSizes.smallGap}>
          <Select
            value={newAsset.type}
            sx={{ marginBottom: GlobalSizes.mediumGap }}
            onChange={(e) => setNewAsset({ ...newAsset, type: e.target.value })}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            fullWidth
          >
            <MenuItem disabled value="">
              Select Type
            </MenuItem>
            {ASSET_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack direction="row" gap={GlobalSizes.smallGap}>
          <Button onClick={saveAsset} variant="contained">
            Save
          </Button>
          <Button onClick={() => setAddAsset(false)} variant="contained" color="secondary">
            Cancel
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default NewAsset;
