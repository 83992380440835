import { AiFilterType, AiResultType, MentionType } from "../state";
import { Stack, Container, Button, CircularProgress, Typography, Avatar, Chip, Badge, Box, ButtonGroup, Tooltip, SelectChangeEvent } from "@mui/material";
import Bar from "../Components/Bar";
import { labels, severity, SOCIAL_ICONS, USER_CUSTOM_LOCAL_VIEW, avatarCache } from "../labels";
import { useCallback, useEffect, useState, useLayoutEffect, useMemo } from "react";
import { GlobalSizes, pagesContainerMargins } from "../size";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridInitialState,
} from "@mui/x-data-grid-pro";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PersonIcon from "@mui/icons-material/Person";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import SocialEmbed from "../Components/SocialEmbed";
import Note from "../Components/Note";
import CustomTableViews from "../Components/monitoring/CustomTableViews";
import { SimilarAlerts, getIdenticalAlert, getSimilarSeverity } from "../Components/SimilarAlerts";
import FlagIcon from "@mui/icons-material/Flag";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SeverityChip from "../Components/SeverityChip";
import RefreshAiFilter from "../Components/investigation/ai-filter/RefreshAiFilter";
import LinearProgress from "@mui/material/LinearProgress";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import { getViews, setViews } from "../team-util";
import { useWsContext } from "../ws-context";
import AiFilterView from "../Components/investigation/ai-filter/AiFilterView";

const initialGridState: GridInitialState = {
  filter: {
    filterModel: {
      items: [{ field: "status", operator: "not", value: "hidden" }],
    },
  },
  sorting: {
    sortModel: [{ field: "detection_date", sort: "desc" }],
  },
  pagination: {
    paginationModel: { pageSize: 1000, page: 0 },
  },
  pinnedColumns: { right: ["status"] },
  density: "standard",
};

function LinearLoadingOverlay() {
  const { dashboardState, isLoadingMentions } = useWsContext();
  return <div style={{ width: "100%", height: "5px" }}>{!dashboardState.customer_id || isLoadingMentions ? <LinearProgress /> : null}</div>;
}

function Monitoring() {
  const { mentionsState, dashboardState, emitNewData, updateAlerts, socketRef } = useWsContext();
  const nav = useNavigate();
  const [isUploadingCsv, setIsUploadingCsv] = useState({
    name: "",
    status: "",
  });
  const [tableStateChanged, setTableStateChanged] = useState(false);
  const [currentView, setCurrentView] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const apiRef = useGridApiRef();

  const autosizeColumns = useCallback(() => {
    if (!apiRef.current?.autosizeColumns) return;
    apiRef.current.autosizeColumns({
      includeHeaders: true,
      includeOutliers: true,
    });
  }, [apiRef]);

  useLayoutEffect(() => {
    const saveSnapshot = () => {
      if (!apiRef?.current?.exportState || !dashboardState.customer_id) return;
      if (!getViews()[dashboardState.customer_id]) {
        localStorage.setItem(USER_CUSTOM_LOCAL_VIEW, JSON.stringify(apiRef.current.exportState()));
      }
    };
    window.addEventListener("beforeunload", saveSnapshot);

    return () => {
      window.removeEventListener("beforeunload", saveSnapshot);
      saveSnapshot();
    };
  }, [apiRef, dashboardState.customer_id]);

  const handleUploadCsv = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !file.type.endsWith("csv")) {
      console.error("No appropriate file selected");
      event.target.value = "";
      return;
    }

    setIsUploadingCsv({ name: file.name, status: "loading" });
    const reader = new FileReader();
    reader.onload = async (e) => {
      const csv = e.target?.result;
      if (!csv) return;
      emitNewData({ action: "uploadCsv", csv });
      const handleWebSocketMessage = (event: MessageEvent) => {
        const { data } = JSON.parse(event.data);
        if (data?.csv === "completed") {
          setIsUploadingCsv({ name: file.name, status: "completed" });
        }
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      };
      event.target.value = "";
      socketRef.current?.addEventListener("message", handleWebSocketMessage);
    };
    reader.readAsText(file);
  };

  const updateAlertRequest = useCallback(
    async (mentionToUpdate: MentionType) => {
      const mention = {
        ...mentionToUpdate,
        customer_id: dashboardState.customer_id,
      };

      emitNewData({ action: "updateAlert", mention });
    },
    [dashboardState.customer_id, emitNewData]
  );

  const updateWhitelisted = useCallback(
    (mentionToUpdate: MentionType) => {
      if (mentionToUpdate.whitelisted) {
        const { alertType, ...rest } = mentionToUpdate;
        updateAlerts({ ...rest, severity: "low" });
        updateAlertRequest({ url: mentionToUpdate.url, severity: "low", whitelisted: true });
        return;
      }
      updateAlerts(mentionToUpdate);
      updateAlertRequest({ url: mentionToUpdate.url, whitelisted: false });
    },
    [updateAlertRequest, updateAlerts]
  );

  const handleViewChange = (event: SelectChangeEvent<string>) => {
    if (!dashboardState.customer_id) return;
    setCurrentView(event.target.value);
    const allViews = getViews();
    if (event.target.value === labels.monitoring.DEFAULT_VIEW) {
      restoreStateToDefault();
      const { [dashboardState.customer_id]: viewToRemove, ...restViews } = allViews;
      setViews(restViews);
      return;
    }
    const chosenView = dashboardState.saved_views?.[event.target.value];
    if (!chosenView) return;
    apiRef.current.restoreState(chosenView);
    setViews({ ...allViews, [dashboardState.customer_id]: event.target.value });
  };

  const aiFilterValueGetter = useCallback(
    (cell: AiFilterType, row: MentionType, ai_filter_key: keyof MentionType) => {
      const identicalAlert = getIdenticalAlert(row, mentionsState);
      let aiFilter = identicalAlert?.[ai_filter_key] as AiFilterType;

      const aiFilterData = identicalAlert && aiFilter?.status !== "failed" ? aiFilter : cell;
      if (!aiFilterData) return null;
      if (!aiFilterData.results) return aiFilterData.reason;
      const trueAnswers = aiFilterData.results?.filter((q: AiResultType) => {
        return q.content?.booleanAnswer;
      });
      return trueAnswers?.length.toString().padStart(5, "0") + " " + trueAnswers?.map((q) => q.key?.replaceAll("_", " ")).join(" ");
    },
    [mentionsState]
  );

  const aiFilterRenderCell = (aiFilter: AiFilterType, params: GridRenderCellParams, mentions: MentionType[], isBeta: boolean) => {
    let fromSimilarFilter = false;
    if (!isBeta && (params.row?.similar?.[0]?.score || 0) > 0.9) {
      const similarMention = mentions.find((m) => m.url === params.row.similar?.[0].url);
      if (similarMention?.ai_filter && similarMention?.ai_filter.status !== "failed") {
        fromSimilarFilter = true;
        aiFilter = similarMention?.ai_filter;
      }
    }
    if (!aiFilter) return null;
    if (!aiFilter.results) {
      return (
        <Tooltip title={aiFilter.reason}>
          <Chip size="small" label={aiFilter.status} />
        </Tooltip>
      );
    }
    const trueQuestions = aiFilter.results?.map((value: AiResultType, index: number) => (
      <Tooltip title={value.content?.explanation} key={index}>
        <Typography variant="body2" sx={{ display: "inline-block", margin: "2px" }}>
          {`${value.key}${index === (aiFilter.results?.length ?? 0) - 1 ? "" : ", "}`}
        </Typography>
      </Tooltip>
    ));
    return (
      <div>
        <Tooltip key={params.row.url} title={labels.ai.success_count(trueQuestions.length) + (fromSimilarFilter ? " (from similar alert)" : "")}>
          <Chip size="small" icon={fromSimilarFilter ? <FindReplaceIcon /> : <TroubleshootIcon />} label={trueQuestions.length} />
        </Tooltip>{" "}
        {trueQuestions}
      </div>
    );
  };

  const generateColumns = useCallback(
    (mentions: MentionType[]) => {
      function customizeDateColumn(columnDef: GridColDef, value: number) {
        const isUnixTimestamp = typeof value === "number" && value > 1000000000000;
        if (isUnixTimestamp) {
          columnDef.type = "date" as const;
          columnDef.valueGetter = (cellValue) => {
            if (!cellValue) return null;
            return new Date(cellValue);
          };
        }
        return columnDef;
      }

      const allColumns = mentions.reduce((acc, mention) => {
        Object.keys(mention).forEach((key) => {
          if (!acc.find((column) => column.field === key)) {
            let columnDef: GridColDef = { field: key, headerName: key };
            const value = mention[key as keyof MentionType];
            if (typeof value === "number") {
              columnDef = customizeDateColumn(columnDef, value);
            }
            acc.push(columnDef);
          }
        });
        return acc;
      }, [] as GridColDef[]);

      const defaultColumns = [
        {
          field: "avatar",
          headerName: "👤",
          width: 60,
          renderCell: (params: GridRenderCellParams) => {
            const SelectedIcon = SOCIAL_ICONS[params.row.source] || PersonIcon;
            return (
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={<SelectedIcon color="disabled" fontSize="small" />}
              >
                <Avatar alt={params.row.user} src={avatarCache(params.value)} />
              </Badge>
            );
          },
        },
        {
          field: "user",
          headerName: "User",
          width: 150,
        },
        {
          field: "source",
          headerName: "Source",
          width: 120,
        },
        {
          field: "detection_date",
          headerName: "Detected",
          type: "date" as const,
          valueGetter: (value: Date) => {
            return new Date(value);
          },
          valueFormatter: (value: Date) => {
            const date = new Date(value);
            return date.toLocaleDateString(undefined, { month: "short", day: "numeric" });
          },
        },
        {
          field: "full_content_length",
          headerName: "Full content length",
          type: "number" as const,
          valueGetter: (value: string, params: MentionType) => {
            return params.full_content && params.full_content.split(/\s+/).length;
          },
        },
        {
          field: "creation_date",
          headerName: "Created",
          minWidth: 90,
          type: "date" as const,
          valueGetter: (value: Date) => {
            return value ? new Date(value) : 0;
          },
        },
        {
          field: "description_short",
          headerName: "Content",
          width: 300,
          maxWidth: 600,
        },
        {
          field: "url",
          headerName: "Link",
          width: 300,
          maxWidth: 600,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Link to={params.row.url} target="_blank" rel="noopener noreferrer">
                {params.row.url}
              </Link>
            );
          },
        },
        {
          field: "alertType",
          headerName: "Risk",
        },
        {
          field: "type",
          headerName: "Item",
        },
        {
          field: "severity",
          headerName: "Severity",
          type: "singleSelect" as const,
          editable: true,
          valueGetter: (value: string, params: MentionType) => {
            const finalValue = params?.manual_severity || getSimilarSeverity(params, mentionsState) || value || "medium";
            return severity[finalValue.toUpperCase()].value;
          },
          valueOptions: [
            { value: 4, label: "Critical" },
            { value: 3, label: "High" },
            { value: 2, label: "Medium" },
            { value: 1, label: "Low" },
          ],
          renderCell: (params: GridRenderCellParams) => {
            return <SeverityChip alert={params.row} />;
          },
        },
        {
          field: "similar",
          headerName: "Similarity",
          width: 120,
          type: "number" as const,
          valueGetter: (value: { score: number }[]) => {
            return value?.[0]?.score ? value[0].score * 100 : null;
          },
          renderCell: (params: GridRenderCellParams) => {
            if (params.value === null) return null;
            return (
              <div>
                <Tooltip key={params.row.url} title="Expand row to see similar alerts">
                  <Chip label={params.value.toFixed(2) + "%"} variant="outlined" />
                </Tooltip>
              </div>
            );
          },
        },
        {
          field: "asset",
          headerName: "Asset",
        },
        {
          field: "location",
          headerName: "Location",
        },
        {
          field: "phone_numbers",
          headerName: "Phone numbers",
          valueGetter: (value: string[]) => {
            if (!value) return null;
            return value?.join(", ");
          },
        },
        {
          field: "emails",
          headerName: "Email addresses",
          valueGetter: (value: string[]) => {
            if (!value) return null;
            return value?.join(", ");
          },
        },
        {
          field: "education",
          headerName: "Education",
          valueGetter: (value: { display: string }[]) => {
            if (!value) return null;
            return value?.map((v) => v.display).join(" • ");
          },
        },
        {
          field: "work_history",
          headerName: "Work history",
          valueGetter: (value: { display: string }[]) => {
            if (!value) return null;
            return value?.map((v) => v.display).join(" • ");
          },
        },
        {
          field: "ai_filter",
          headerName: labels.ai.title,
          valueGetter: (cell: AiFilterType, row: MentionType) => {
            return aiFilterValueGetter(cell, row, "ai_filter");
          },
          renderCell: (params: GridRenderCellParams) => {
            return aiFilterRenderCell(params.row.ai_filter, params, mentions, false);
          },
        },
        {
          field: "ai_filter_beta",
          headerName: labels.ai.title_beta,
          valueGetter: (cell: AiFilterType, row: MentionType) => {
            return aiFilterValueGetter(cell, row, "ai_filter_beta");
          },
          renderCell: (params: GridRenderCellParams) => {
            return aiFilterRenderCell(params.row.ai_filter_beta, params, mentions, true);
          },
        },
        {
          field: "refresh_ai_filter",
          headerName: `Refresh ${labels.ai.title}`,
          renderCell: (params: GridRenderCellParams) => {
            return <RefreshAiFilter alert={params.row} />;
          },
        },
        {
          field: "impressions",
          headerName: "Impressions",
        },
        {
          field: "status",
          headerName: "Status",
          type: "singleSelect" as const,
          valueGetter: (value: undefined, row: MentionType) => {
            if (row.hidden) return "hidden";
            if (row.flagged) return "flagged";
            if (row.whitelisted) return "whitelisted";
          },
          valueOptions: [
            { value: "hidden", label: "hidden" },
            { value: "flagged", label: "flagged" },
            { value: "whitelisted", label: "whitelisted" },
          ],
          width: 155,
          renderCell: (params: GridRenderCellParams) => {
            const flagged = params.row.flagged;
            const hidden = params.row.hidden;
            const whitelisted = params.row.whitelisted;
            return (
              dashboardState && (
                <ButtonGroup sx={{ mt: GlobalSizes.smallGap }} color="inherit" size="small" variant="contained" aria-label="Basic button group">
                  <Tooltip title={hidden ? "Unhide" : "Hide"} placement="top">
                    <Button
                      color="inherit"
                      onClick={() => {
                        updateAlertRequest({ url: params.row.url, hidden: !params.row.hidden });
                        updateAlerts({ ...params.row, hidden: !params.row.hidden });
                      }}
                      aria-label="Hide Alert"
                    >
                      {params.row.hidden ? <VisibilityIcon /> : <VisibilityOffIcon color="disabled" />}
                    </Button>
                  </Tooltip>
                  <Tooltip title={flagged ? "See flag" : "Flag"} placement="top">
                    <Button
                      color={flagged ? "error" : "inherit"}
                      onClick={() => {
                        if (flagged) {
                          nav(`/flagging?url=${encodeURIComponent(params.row.url)}`);
                        } else {
                          updateAlerts({ ...params.row, flagged: true, flag_date: Date.now() });
                          updateAlertRequest({ url: params.row.url, flagged: true, flag_date: Date.now() });
                        }
                      }}
                      aria-label="Flag Alert"
                    >
                      <FlagIcon color={!flagged ? "disabled" : "action"} />
                    </Button>
                  </Tooltip>
                  <Tooltip title={whitelisted ? "Remove from whitelist" : "Add to whitelist"} placement="top">
                    <Button
                      color={whitelisted ? "success" : "inherit"}
                      onClick={() => {
                        updateWhitelisted({ ...params.row, whitelisted: !params.row.whitelisted });
                      }}
                      aria-label="Whitelist Alert"
                    >
                      <VerifiedUserIcon color={!whitelisted ? "disabled" : "action"} />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              )
            );
          },
        },
      ];

      //remove all columns that exist in the default columns
      const filteredColumns = allColumns.filter((column) => !defaultColumns.find((defaultColumn) => defaultColumn.field === column.field));
      const columns: GridColDef[] = [...defaultColumns, ...filteredColumns] as GridColDef[];
      const columnVisibilityModel: { [key: string]: boolean } = {};
      for (const column of allColumns) {
        columnVisibilityModel[column.field] = defaultColumns.some((defaultColumn) => defaultColumn.field === column.field);
      }
      columnVisibilityModel["refresh_ai_filter"] = false;
      columnVisibilityModel["ai_filter_beta"] = false;
      return { columns, columnVisibilityModel };
    },
    [aiFilterValueGetter, dashboardState, mentionsState, nav, updateAlertRequest, updateAlerts, updateWhitelisted]
  );

  const { columns, columnVisibilityModel } = useMemo(() => generateColumns(mentionsState), [mentionsState, generateColumns]);

  const restoreStateToDefault = useCallback(() => {
    apiRef.current.restoreState(initialGridState);
    apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
  }, [apiRef, columnVisibilityModel]);

  useEffect(() => {
    if (tableStateChanged || !dashboardState.customer_id) return;
    const savedView = getViews()[dashboardState.customer_id];
    if (savedView) {
      setCurrentView(savedView);
      const view = dashboardState.saved_views?.[savedView];
      if (view) apiRef.current.restoreState(view);
    } else {
      const savedState = JSON.parse(localStorage.getItem(USER_CUSTOM_LOCAL_VIEW) || "{}");
      if (savedState.columns) {
        apiRef.current.restoreState(savedState);
      } else {
        restoreStateToDefault();
      }
    }
    setTableStateChanged(false);
  }, [apiRef, dashboardState.customer_id, tableStateChanged, dashboardState.saved_views, restoreStateToDefault]);

  useEffect(() => {
    if (!dashboardState.customer_id) return;
    const savedView = getViews()[dashboardState.customer_id];
    if (!savedView && dashboardState.customer_id) {
      setTimeout(() => {
        autosizeColumns();
      }, 100);
    }
  }, [dashboardState.customer_id, autosizeColumns]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Tooltip title="Customize filter and column views" placement="top">
          <Button startIcon={<ViewCompactIcon />} onClick={() => setOpenDialog(true)}>
            {currentView && currentView !== labels.monitoring.DEFAULT_VIEW ? `${currentView}${tableStateChanged ? " *" : ""}` : labels.monitoring.DEFAULT_VIEW}
          </Button>
        </Tooltip>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport />
        <Tooltip
          title={
            {
              loading: `Uploading ${isUploadingCsv.name}...`,
              completed: `${isUploadingCsv.name} uploaded successfully!`,
              error: `Failed to upload ${isUploadingCsv.name}, please try again!`,
              processing: labels.monitoring.csv,
            }[isUploadingCsv.status] || "Upload alerts via CSV"
          }
        >
          <Button
            size="small"
            component="label"
            startIcon={
              <Badge
                badgeContent={
                  {
                    completed: <DoneOutlinedIcon fontSize="inherit" />,
                    error: <CloseOutlinedIcon fontSize="inherit" />,
                  }[isUploadingCsv.status] || null
                }
              >
                {{ loading: <CircularProgress size={GlobalSizes.smallFontSize} />, processing: <CircularProgress size={GlobalSizes.smallFontSize} /> }[
                  isUploadingCsv.status
                ] || <FileUploadOutlinedIcon fontSize="small" />}
              </Badge>
            }
          >
            <input type="file" hidden accept=".csv" onChange={handleUploadCsv} />
            Import
          </Button>
        </Tooltip>
      </GridToolbarContainer>
    );
  }

  //!JSX
  return (
    <div>
      <Bar label={labels.monitoring.title + " | " + labels.monitoring.subtitle} />
      <Container sx={{ height: "85vh", minWidth: GlobalSizes.fullSize, ...pagesContainerMargins }}>
        <p>Reporting and anomaly detection with continuous monitoring.</p>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Total Alerts: {mentionsState.length}</Typography>
        </Stack>
        {openDialog && (
          <CustomTableViews
            openDialog={openDialog}
            currentView={currentView}
            setCurrentView={setCurrentView}
            gridState={apiRef.current.exportState()}
            handleCloseDialog={() => {
              setOpenDialog(false);
              setTableStateChanged(false);
            }}
            handleViewChange={handleViewChange}
            tableStateChanged={tableStateChanged}
            restoreStateToDefault={restoreStateToDefault}
          />
        )}
        <LinearLoadingOverlay />
        <DataGridPro
          onColumnVisibilityModelChange={() => setTableStateChanged(true)}
          onDensityChange={() => setTableStateChanged(true)}
          onFilterModelChange={() => setTableStateChanged(true)}
          onSortModelChange={() => setTableStateChanged(true)}
          onPaginationModelChange={() => setTableStateChanged(true)}
          onColumnWidthChange={() => setTableStateChanged(true)}
          onColumnOrderChange={() => setTableStateChanged(true)}
          slots={{ toolbar: CustomToolbar }}
          rows={mentionsState}
          columns={columns}
          apiRef={apiRef}
          getRowId={(row) => row.url}
          pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
          pagination
          processRowUpdate={async (newRow, oldRow) => {
            const sevKey = Object.keys(severity).find((key) => severity[key].value === newRow.severity);
            newRow.manual_severity = sevKey?.toLowerCase();
            newRow.severity = oldRow.severity;
            updateAlerts(newRow);
            await updateAlertRequest({ manual_severity: newRow.manual_severity, severity: newRow.severity, url: newRow.url });
            return newRow;
          }}
          initialState={initialGridState}
          getDetailPanelHeight={({ row }) => 450}
          getDetailPanelContent={({ row }) => (
            <Box display="flex">
              {row.source && row.type !== "article" && <SocialEmbed source={row.source} url={row.url} urn={row.urn} />}
              <Box sx={{ maxWidth: GlobalSizes.cards.width, wordWrap: "break-word", marginLeft: GlobalSizes.gap }}>
                <AiFilterView alert={row} />
              </Box>
              <Container sx={{ height: GlobalSizes.cards.height, width: GlobalSizes.cards.width }}>
                <SimilarAlerts alert={row} allMentions={mentionsState} />
              </Container>
              <Note mention={row} />
            </Box>
          )}
        />
      </Container>
    </div>
  );
}

export default Monitoring;
