import { InvestigationSizes, GlobalSizes, InvestigationImageSizes } from "../../size";
import { Box, Typography, Stack, IconButton, Tooltip, Divider } from "@mui/material";
import { MentionType } from "../../state";
import { unixToLocaleDate } from "../../labels";
import AddIcon from "@mui/icons-material/Add";
import { useCallback, useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import ManualLinkForm from "./ManualLinkForm";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { useWsContext } from "../../ws-context";
import { decode } from "html-entities";

export const initialLinkState = {
  url: "",
  title: "",
  note: "",
  image: "",
  category: "",
};

function TimeLine({ alert }: { alert?: MentionType }) {
  const { dashboardState, deleteAlert, mentionsState, emitNewData } = useWsContext();
  const [openCategory, setOpenCategory] = useState<string | null>(null);
  const [newLink, setNewLink] = useState<MentionType>(initialLinkState);
  const [editedLink, setEditedLink] = useState<MentionType>(initialLinkState);

  const handleCategoryClick = useCallback((category: string) => {
    setNewLink((prevLink) => ({
      ...prevLink,
      category: category || "",
    }));
    setOpenCategory((prevCategory) => (prevCategory === category ? null : category));
  }, []);

  async function onDelete(link: MentionType) {
    if (!window.confirm("Are you sure you want to delete this link?")) return;
    deleteAlert(link.url);
    emitNewData({ action: "removeMention", mention: link });
  }

  const linksByCategory: Record<string, MentionType[]> = {};

  mentionsState
    .filter((m) => m.parent_mention === alert?.url)
    .forEach((alert) => {
      if (!alert.category) return;
      if (!linksByCategory[alert.category]) {
        linksByCategory[alert.category] = [];
      }
      linksByCategory[alert.category].push(alert);
    });

  // Sort the category names within the linksByCategory object
  const orderedLinksByCategory = Object.fromEntries(
    Object.entries(linksByCategory).sort((a, b) => {
      const sortA = dashboardState.settings?.category_sort?.[a[0]] || 1000;
      const sortB = dashboardState.settings?.category_sort?.[b[0]] || 1000;
      return sortA - sortB || a[0].localeCompare(b[0]);
    })
  );

  return (
    <div>
      <Box>
        {Object.entries(orderedLinksByCategory).map(([category, manualLinks]) => (
          <Box key={category} sx={{ display: "flex", flexDirection: "column" }}>
            <Stack direction="row" spacing={2} sx={{ margin: GlobalSizes.gap }}>
              <Typography gutterBottom variant="h5" component="div" marginTop={InvestigationSizes.mtTypography}>
                {category}
                <Divider />
              </Typography>
              <Tooltip title={openCategory === category ? "Cancel" : "Add new link"} placement="top">
                <IconButton size="small" onClick={() => handleCategoryClick(category)}>
                  {openCategory === category ? <RemoveIcon fontSize="small" /> : <AddIcon fontSize="small" />}
                </IconButton>
              </Tooltip>
            </Stack>
            {openCategory === category && (
              <Stack maxWidth={GlobalSizes.halfSize}>
                <ManualLinkForm manualLink={newLink} setManualLink={setNewLink} currentAlert={alert} />
              </Stack>
            )}
            {manualLinks.map((item, index) => (
              <Box id={item.url} sx={{ display: "flex", flexDirection: "column" }} key={`${item.url}_${index}`}>
                <Stack direction="row" sx={{ margin: GlobalSizes.gap }}>
                  {editedLink?.url === item.url ? (
                    <ManualLinkForm manualLink={editedLink} setManualLink={setEditedLink} isEditing={true} />
                  ) : (
                    <Box sx={{ width: InvestigationSizes.postsDropDownMaxWidth, alignContent: "left" }}>
                      {item.title && (
                        <Typography gutterBottom variant="h5" component="div" sx={{ margin: GlobalSizes.gap }}>
                          {item.title}
                        </Typography>
                      )}
                      {item.url && (
                        <Box
                          sx={{ margin: GlobalSizes.gap, maxWidth: GlobalSizes.largeSize, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                        >
                          <a href={`${item.url}`} target="_blank" rel="noreferrer">
                            {item.url}
                          </a>
                        </Box>
                      )}
                      {item.note && <Typography sx={{ whiteSpace: "pre-wrap", margin: GlobalSizes.gap }}>{item.note}</Typography>}
                      {item.description_short && (
                        <Typography color="GrayText" sx={{ whiteSpace: "pre-wrap", mx: GlobalSizes.gap, mt: GlobalSizes.gap, fontStyle: "italic" }}>
                          {decode(item.description_short)}
                        </Typography>
                      )}
                      {item.creation_date && (
                        <Typography color="GrayText" sx={{ mx: GlobalSizes.gap, mt: GlobalSizes.gap }}>
                          {unixToLocaleDate(item.creation_date)}
                        </Typography>
                      )}
                      {item.image && (
                        <a href={item.image} target="_blank" rel="noreferrer">
                          <Box
                            component="img"
                            sx={{
                              maxHeight: InvestigationImageSizes.height,
                              maxWidth: InvestigationImageSizes.width,
                              margin: GlobalSizes.gap,
                              cursor: "pointer",
                            }}
                            src={item.image}
                          />
                        </a>
                      )}
                    </Box>
                  )}
                  <Stack direction="row" spacing={2} sx={{ margin: GlobalSizes.gap }} alignSelf="flex-start">
                    <Tooltip title={editedLink?.url === item.url ? "Cancel" : "Edit"} placement="top">
                      <IconButton size="small" onClick={() => (editedLink?.url === item.url ? setEditedLink(initialLinkState) : setEditedLink(item))}>
                        {editedLink?.url === item.url ? <RemoveIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete link" placement="top">
                      <IconButton size="small" onClick={() => onDelete(item)}>
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </div>
  );
}

export default TimeLine;
